import Vue from "vue";
import { AclInstaller, AclCreate, AclRule } from "vue-acl";
import router from "@/router";

Vue.use(AclInstaller);

let initialRole = "public";

// Get Vuex Local Storage Item in order to get User Type.
const vuex = JSON.parse(localStorage.getItem("vuex"));
if (vuex !== null && vuex.user.info.user_type !== undefined) {
	initialRole = vuex.user.info.user_type;
}

export default new AclCreate({
	initial: initialRole,
	notfound: {
		path: "/not_authorized",
		forwardQueryParams: true
	},
	router,
	acceptLocalRules: true,
	globalRules: {
		isPharmacy: new AclRule("pharmacy").or("alquimia").or("pharmacygroup").generate(),
		isPharmacyGroup: new AclRule("pharmacygroup").generate(),
		isCandidate: new AclRule("candidate").generate(),
		isExpert: new AclRule("expert").generate(),
		isUser: new AclRule("user")
			.or("pharmacy")
			.or("pharmacygroup")
			.or("alquimia")
			.or("candidate")
			.or("expert")
			.generate(),
		isNormalUser: new AclRule("normalUser")
			.or("pharmacy")
			.or("pharmacygroup")
			.or("alquimia")
			.or("candidate")
			.generate(),
		isPharmacyExpert: new AclRule("pharmacyExpert")
			.or("pharmacy")
			.or("pharmacygroup")
			.or("alquimia")
			.or("expert")
			.generate(),
		isPublic: new AclRule("public")
			.or("pharmacy")
			.or("pharmacygroup")
			.or("alquimia")
			.or("candidate")
			.or("expert")
			.or("default")
			.generate()
	}
});
