export default {
	isLogged: (state) => {
		return state.isLogged;
	},
	accessToken: (state) => {
		return state.accessToken;
	},
	refreshToken: (state) => {
		return state.refreshToken;
	},
	childId: (state) => {
		return state.childId;
	},
	groupId: (state) => {
		return state.groupId;
	},
	info: (state) => {
		return state.info;
	},
	type: (state) => {
		return state.info.user_type;
	},
	language: (state) => {
		return state.language;
	},
	showAddToHomescreen(state) {
		return state.showAddToHomescreen;
	},
	ShowCandidateInterest(state) {
		return state.ShowCandidateInterest;
	},
	ShowOfferExplain(state) {
		return state.ShowOfferExplain;
	},
	initUserType(state) {
		return state.init_user_type;
	}
};
