// Mutation Types
import * as types from "./types";

// State

export default {
  [types.GET_CANDIDATES](state, payload) {
    state.candidates = payload.results;
  },
  [types.GET_CANDIDATE_BY_ID](state, payload) {
    state.candidate = payload;
  },
  [types.CREATE_CANDIDATE](state, payload) {
    state.candidates.push(payload);
  },
  [types.UPDATE_CANDIDATE](state, payload) {
    state.candidates[state.candidates.indexOf(x => x.id == payload.id)] = payload;
  },
  [types.GET_OTHER_FILES](state, payload) {
    state.otherFiles = payload.results
  },
  [types.GET_EXPERT_FILES](state, payload) {
    state.expertFiles = payload.results
  },
};