import Vue from "vue";
import App from "@/App.vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import * as FullStory from "@fullstory/browser";
import 'regenerator-runtime';

import "@/registerServiceWorker";
import "./scss/main.scss";

// Vuetify Component Framework
import vuetify from "@/plugins/vuetify";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";

// v mask
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

// Tailwind
import "@/assets/css/main.css";

// API Service
import ApiService from "@/services";
ApiService.init();

// ACL
import acl from "@/acl/acl.js";

// Vue Router
import router from "@/router";

// Vuex Store
import store from "@/store";

// i18n
import i18n from "@/i18n";

// notifications
import notifications from "vue-notification";
Vue.use(notifications);
// momentjs
import VueMoment from "vue-moment";
Vue.use(VueMoment);

// Filters
import "@/filters";

// Vue.config.productionTip = false
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";

import videojs from "video.js";

// videojs plugin
const Plugin = videojs.getPlugin("plugin");
class ExamplePlugin extends Plugin {
	// something...
}
videojs.registerPlugin("examplePlugin", ExamplePlugin);

// videojs language
videojs.addLanguage("es", {
	Pause: "Pausa"
	// something...
});

// more videojs api...

// vue component...
Vue.use(VuetifyGoogleAutocomplete, {
	apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
	// version: '...', // Optional
	// language: '...', // Optional
	libraries: "places",
	installComponents: true, // Optional (default: true) - false, if you want to locally install components
	vueGoogleMapsCompatibility: false // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
});

Vue.use(VueTelInputVuetify, {
	vuetify
});
if (process.env.VUE_APP_SENTRY_ENVIRONMENT == "production") {
	Sentry.init({
		Vue,
		release: "apotalent-app@" + process.env.PACKAGE_VERSION,
		dsn: "https://df3a088bd21f47ecb1566aadf957ae16@o744966.ingest.sentry.io/5790224",
		integrations: [ new Integrations.BrowserTracing() ],
		environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
		logErrors: true
	});
	FullStory.init({ orgId: "1335AP" });
}
Vue.prototype.$FullStory = FullStory;
// eslint-disable-next-line no-undef
new Vue({
	router,
	notifications,
	store,
	VueMoment,
	i18n,
	acl,
	vuetify,
	render: (h) => h(App)
}).$mount("#app");
