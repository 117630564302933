// Mutation Types
import * as types from "./types";

// Services
import CandidateService from "@/services/CandidateService.js";

export default {
  async getCandidates({ commit }) {
    const data = await CandidateService.getCandidates().then(
      response => response.data
    );
    commit(types.GET_CANDIDATES, data);
  },
  async getCandidateById({ commit }, candidate_id) {
    const data = await CandidateService.getCandidateById(candidate_id).then(
      response => response.data
    );
    commit(types.GET_CANDIDATE_BY_ID, data);
  },
  async getCandidateByIdPharmacy({ commit }, payload) {
    const data = await CandidateService.getCandidateByIdPharmacy(payload).then(
      response => response.data
    );
    commit(types.GET_CANDIDATE_BY_ID, data);
  },
  async createCandidate({ commit }, payload) {
    const data = await CandidateService.createCandidate(payload).then(
      response => response.data
    );
    commit(types.CREATE_CANDIDATE, data);
  },
  async updateCandidate({ commit }, payload) {
    const data = await CandidateService.updateCandidate(payload).then(
      response => response.data
    );
    commit(types.UPDATE_CANDIDATE, data);
  },
  async getOtherFiles({ commit }, candidate_id = null) {
    const data = await CandidateService.getOtherFiles(candidate_id).then(
      response => response.data
    );
    commit(types.GET_OTHER_FILES, data);
  },
  async getExpertFiles({ commit }, payload) {
    const data = await CandidateService.getExpertFiles(payload).then(
      response => response.data
    );
    commit(types.GET_EXPERT_FILES, data);
  }
};
