export default {
  isLogged: false,
  childId: null,
  accessToken: null,
  refreshToken: null,
  info: {},
  language: null,
  showAddToHomescreen: true,
  ShowCandidateInterest: true,
  ShowOfferExplain: true,
  initUserType:null,
};
