// Mutation Types
import * as types from "./types";

// Services
import PharmacyService from "@/services/PharmacyService.js";

export default {
  async getPharmacies({ commit }) {
    const data = await PharmacyService.getPharmacies().then(
      (response) => response.data
    );
    commit(types.GET_PHARMACIES, data);
  },
  async getPharmacyGroup({ commit }, pharmacy_group_id) {
    const data = await PharmacyService.getPharmacyGroup(pharmacy_group_id).then(
      (response) => response.data
    );
    commit(types.GET_PHARMACY_GROUP_BY_ID, data);
  },
  async getPharmacyById({ commit }, pharmacy_id) {
    const data = await PharmacyService.getPharmacyById(pharmacy_id).then(
      (response) => response.data
    );
    commit(types.GET_PHARMACY_BY_ID, data);
  },
  async getPharmacy({ commit }) {
    const data = await PharmacyService.getPharmacy().then(
      (response) => response.data
    );
    commit(types.GET_PHARMACY_BY_ID, data);
  },
  async updatePharmacy({ commit }, payload) {
    const data = await PharmacyService.updatePharmacy(payload).then(
      (response) => response.data
    );
    commit(types.UPDATE_PHARMACY, data);
  },
  async updatePharmacyGroup({ commit }, payload) {
    const response = await PharmacyService.updatePharmacyGroup(payload);
    commit(types.UPDATE_PHARMACY_GROUP, response.data);
    return response.status;
  },
  async getPharmacyPayInfo({ commit }, pharmacy_id) {
    const data = await PharmacyService.getPharmacyPayInfo(pharmacy_id).then(
      (response) => response.data
    );
    commit(types.GET_PHARMACY_PAY_INFO, data);
  },
  async createPharmacy({ commit }, payload) {
    //logo is sent separately
    delete payload.video;
    delete payload.logo;
    let response = await PharmacyService.registerPharmacy(payload);
    commit(types.CREATE_PHARMACY, response.data);
    payload.pharmacy_owner.pharmacy = response.data.id;
    payload.pharmacy_contact.pharmacy = response.data.id;

    PharmacyService.registerPharmacyOwner(payload.pharmacy_owner);
    if (payload.pharmacy_contact.email) {
      PharmacyService.registerPharmacyContact(payload.pharmacy_contact);
    }
    if (payload.logo)
      PharmacyService.uploadLogo(response.data.id, payload.pharmacy.logo);
    if (payload.video)
      PharmacyService.uploadVideo(response.data.id, payload.pharmacy.video);
    // response=await AuthService.login(payload.user)
    return response.status;
  },
  async createPharmacyGroup({ commit }, payload) {
    const response = await PharmacyService.createPharmacyGroup(payload);
    if (payload.logo) {
      await PharmacyService.uploadGroupLogo(response.data.id, payload.logo);
    }
    commit(types.CREATE_PHARMACY_GROUP, response.data);
    return response.status;
  },
};
