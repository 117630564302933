export default {
  matches: state => {
    return state.matches;
  },
  candidates: state => {
    return state.candidates;
  },
  candidate: state => {
    return state.candidate;
  },
  offers: state => {
    return state.offers;
  },
  offer: state => {
    return state.offer;
  },

};
