import config from "@/config";
import ApiService from "@/services";

export const CandidateService = {
  registerCandidate(payload) {
    console.log(payload, "candidateservice")
    return ApiService.post(`${config.CORE_ENDPOINT}candidates/`, {
      referrer_name: payload.referrer_name,
      phone_number: payload.phone_number,
      birthday_date: payload.birthday_date,
      is_only_state: payload.is_only_state,
      accept_terms_conditions: payload.accept_terms_conditions,
      accept_privacy_policy: payload.accept_privacy_policy,
      experience_fields: payload.experience_fields,
      candidate_languages: payload.candidate_languages,
      pharmacy_experience: payload.pharmacy_experience,
      computer_programs: payload.computer_programs,
      others_computer_programs: payload.othersComputerPrograms,
      user: payload.user,
      address: payload.address,
      gender: payload.gender,
      full_name: payload.full_name,
      educations: payload.educations,
      title: payload.title,
      expertises: payload.expertises,
      active_search: payload.active_search,
      working_day: payload.working_day,
      profile:
        Object.keys(payload.profile).length !== 0 ? payload.profile : null,
      // experience_time: payload.experience_time,
      interested_states: payload.interested_states,
    });
  },
  getCandidates() {
    return ApiService.get(`${config.CORE_ENDPOINT}candidates`);
  },
  getCandidateById(candidate_id) {
    return ApiService.get(`${config.CORE_ENDPOINT}candidates/${candidate_id}`);
  },
  getCandidateByIdPharmacy(payload) {
    return ApiService.query(
      `${config.CORE_ENDPOINT}candidates/${payload.candidate_id}`,
      {
        offer_id: payload.offer_id,
      }
    );
  },
  updateCandidate(payload) {
    let simpleOthersComputerPrograms = [];
    for (let elem of payload.othersComputerPrograms) {
      simpleOthersComputerPrograms.push(elem.programs);
    }
    payload.othersComputerPrograms=simpleOthersComputerPrograms;
    return ApiService.put(`${config.CORE_ENDPOINT}candidates/${payload.id}`, {
      phone_number: payload.phone_number,
      birthday_date: payload.birthday_date,
      is_only_state: payload.is_only_state,
      // accept_terms_conditions: payload.accept_terms_conditions,
      // accept_privacy_policy: payload.accept_privacy_policy,
      experience_fields: payload.experience_fields,
      candidate_languages: payload.candidate_languages,
      user: payload.user,
      address: payload.address,
      gender: payload.gender,
      full_name: payload.full_name,
      educations: payload.educations,
      title: payload.title,
      expertises: payload.expertises,
      working_day: payload.working_day,
      active_search: payload.active_search,
      others_computer_programs: payload.othersComputerPrograms,
      computer_programs: payload.computer_programs,
      profile:
        Object.keys(payload.profile).length !== 0 ? payload.profile : null,
      // experience_time: payload.experience_time,
      // interested_states: payload.interested_states
    });
  },
  deleteCandidate(candidate_id) {
    return ApiService.delete(
      `${config.CORE_ENDPOINT}candidates/`,
      candidate_id
    );
  },
  /*   uploadWorkingDays(candidate_id) {
    return ApiService.uploadWorkingDays(
      `${config.CORE_ENDPOINT}candidates/working-days/${candidate_id}`
    )
  }, */
  uploadVideo(candidate_id, video) {
    let formData = new FormData();
    formData.append("video", video, video.name);
    return ApiService.put(
      `${config.CORE_ENDPOINT}candidates/${candidate_id}/files/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  uploadCurriculum(candidate_id, cv) {
    let formData = new FormData();
    formData.append("curriculum", cv, cv.name);
    return ApiService.put(
      `${config.CORE_ENDPOINT}candidates/${candidate_id}/files/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  uploadOtherFiles(candidate_id, files) {
    let formData = new FormData();
    formData.append("other_files", files, files.name);
    return ApiService.put(
      `${config.CORE_ENDPOINT}candidates/${candidate_id}/files/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  uploadAvatar(candidate_id, avatar) {
    let formData = new FormData();
    formData.append("avatar", avatar, avatar.name);
    return ApiService.put(
      `${config.CORE_ENDPOINT}candidates/${candidate_id}/files/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  uploadExpertFiles(candidate_id, offer_id, expertFiles) {
    let formData = new FormData();
    formData.append("file", expertFiles, expertFiles.name);
    formData.append("candidate_id", candidate_id);
    formData.append("offer_id", offer_id);
    return ApiService.post(`${config.CORE_ENDPOINT}expert-files/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  uploadFiles(candidate_id, files) {
    let formData = new FormData();
    let fTypes = ["video", "curriculum", "avatar", "other_files"];
    for (let type of fTypes) {
      if (files[type]) formData.append(type, files[type], files[type].name);
    }
    return ApiService.put(
      `${config.CORE_ENDPOINT}candidates/${candidate_id}/files/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  getOtherFiles(candidate_id = null) {
    if (!candidate_id)
      return ApiService.get(`${config.CORE_ENDPOINT}candidate-other-files`);
    else
      return ApiService.query(`${config.CORE_ENDPOINT}candidate-other-files`, {
        candidate_id: candidate_id,
      });
  },
  getExpertFiles(payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}expert-files`, {
      candidate_id: payload.candidate_id,
      offer_id: payload.offer_id,
    });
  },
};

export default CandidateService;
