// Mutation Types
import * as types from "./types";

// State

export default {
  [types.GET_TERMS_AND_CONDITIONS](state, payload) {
    state.termsAndConditions = payload.results
  },
  [types.GET_FAQS](state, payload) {
    state.faqs.push(...payload.results)
  },
  [types.CLEAN_FAQS](state) {
    state.faqs = []
  },
  [types.GET_PRIVACY_POLICY](state, payload) {
    state.privacyPolicy = payload.results
  }
};
