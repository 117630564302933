// Mutation Types
import * as types from "./types";

// Services
import LanguageService from "@/services/LanguageService.js";

export default {
    async getLanguageLeverls({ commit },) {
        const data = await LanguageService.getLanguageLeverls().then(
            response => response.data
        );
        commit(types.GET_LANGUAGE_LEVELS, data);
    },
    async createLanguageOffer({ commit }, payload) {
        const response = await LanguageService.createLanguageOffer(payload)
        commit(types.CREATE_LANGUAGE_OFFER, response);
        return response
    },
    setLanguage({ commit }, payload) {
        commit(types.SET_LANGUAGE, payload)
    }
};