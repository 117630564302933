// Mutation Types
import * as types from "./types";

// Services
import LegalService from "@/services/LegalService.js";

export default {
  async getTermsAndConditions({ commit }, offer_id) {
    const data = await LegalService.getTermsAndConditions(offer_id).then(
      response => response.data
    );
    commit(types.GET_TERMS_AND_CONDITIONS, data);
  },
  async getFaqs({ commit }, offer_id) {
    const data = await LegalService.getFaqs(offer_id).then(
      response => response.data
    );
    commit(types.GET_FAQS, data);
  },
  async getprivacyPolicy({ commit }, offer_id) {
    const data = await LegalService.getprivacyPolicy(offer_id).then(
      response => response.data
    );
    commit(types.GET_PRIVACY_POLICY, data);
  },
  async cleanFaqs({ commit }) {
    commit(types.CLEAN_FAQS)
  }
};
