// Mutation Types
import * as types from "./types";

// Services
import CoreService from "../../services/CoreService";
import NotificationsService from "../../services/NotificationsService";

export default {
  async getPositions({ commit }) {
    const data = await CoreService.getPositions().then(
      response => response.data
    );
    commit(types.GET_POSITIONS, data);
  },
  async getTitles({ commit }) {
    const data = await CoreService.getTitles().then(response => response.data);
    commit(types.GET_TITLES, data);
  },

  async getTerms({ commit }) {
    const data = await CoreService.getTerms().then(response => response.data);
    commit(types.GET_TERMS, data);
  },
  async getUserTerms({ commit }) {
    const data = await CoreService.getUserTerms().then(
      response => response.data
    );
    commit(types.GET_USER_TERMS, data);
  },
  async getWorkingDays({ commit }) {
    const data = await CoreService.getWorkingDays().then(
      response => response.data
    );
    commit(types.GET_WORKING_DAYS, data);
  },
  async getComputerPrograms({ commit }) {
    const data = await CoreService.getComputerPrograms().then(
      response => response.data
    );
    commit(types.GET_COMPUTER_PROGRAMS, data)
  },
  /* Datos de computer programs filtrados */
/*   async getFilterComputerPrograms({ commit }, payload) {
    const data = await CoreService.getFilterComputerPrograms(payload).then(
      response => response.data
    );
    commit(types.GET_FILTER_COMPUTER_PROGRAMS, data)
  }, */
  async getContractTypes({ commit }) {
    const data = await CoreService.getContractTypes().then(
      response => response.data
    );
    commit(types.GET_CONTRACT_TYPES, data);
  },
  async getOpenHours({ commit }) {
    const data = await CoreService.getOpenHours().then(
      response => response.data
    );
    commit(types.GET_OPEN_HOURS, data);
  },
  async getLanguages({ commit }) {
    const data = await CoreService.getLanguages().then(
      response => response.data
    );
    commit(types.GET_LANGUAGES, data);
  },
  async getExpertises({ commit }) {
    const data = await CoreService.getExpertises().then(
      response => response.data
    );
    commit(types.GET_EXPERTISES, data);
  },
  async getExperienceFields({ commit }) {
    const data = await CoreService.getExperienceFields().then(
      response => response.data
    );
    commit(types.GET_EXPERIENCE_FIELDS, data);
  },
  async getGenders({ commit }) {
    const data = await CoreService.getGenders().then(response => response.data);
    commit(types.GET_GENDERS, data);
  },
  async getStates({ commit }) {
    const data = await CoreService.getStates().then(response => response.data);
    commit(types.GET_STATES, data);
  },
  async getCandidateStatusChoices({ commit }) {
    const data = await CoreService.getCandidateStatusChoices().then(
      response => response.data
    );
    commit(types.GET_CANDIDATE_STATUS_CHOICES, data);
  },
  async getStatusChoices({ commit }, userType) {
    let data = null;
    if (userType == "expert")
      data = await CoreService.getExpertStatusChoices().then(
        response => response.data
      );
    else
      data = await CoreService.getPharmacyStatusChoices().then(
        response => response.data
      );

    commit(types.GET_PHARMACY_STATUS_CHOICES, data);
  },
  async getNotifications({ commit }) {
    const data = await NotificationsService.getNotifications().then(
      response => response.data
    );
    commit(types.GET_NOTIFICATIONS, data);
  },
  async getKeyValues({ commit }) {
    const data = await CoreService.getKeyValues().then(
      response => response.data
    );
    commit(types.GET_KEYVALUES, data);
  },
  async getPharmacyExperienceYears({ commit }) {
    const data = await CoreService.getPharmacyExperienceYears().then(
      response => response.data
    );
    commit(types.GET_PHARMACY_EXPERIENCE_YEARS, data.results);
  },
  async getPharmacyWorkerNumber({ commit }) {
    const data = await CoreService.getPharmacyWorkerNumber().then(
      response => response.data
    );
    commit(types.GET_PHARMACY_WORKER_NUMBER, data.results);
  },
  async getCompensationModel({ commit }) {
    const data = await CoreService.getCompensationModel().then(
      response => response.data
    );
    commit(types.GET_COMPENSATION_MODEL, data.results);
  },
  async getWorkingHours({ commit }) {
    const data = await CoreService.getWorkingHours().then(
      response => response.data
    );
    commit(types.GET_WORKING_HOURS, data.results);
  },
  async getUniversities({ commit }) {
    const data = await CoreService.getUniversities().then(
      response => response.data
    );
    commit(types.GET_UNIVERSITIES, data.results);
  },
  async getHomologatedChoices({ commit }) {
    const data = await CoreService.getHomologatedChoices().then(
      response => response.data
    );
    commit(types.GET_HOMOLOGATED_CHOICES, data.results);
  },

  async markAllNotificationsAsRead() {
    await NotificationsService.markAllNotificationsAsRead().then(
      response => response.data
    );
  },
  async readNotifications({ commit }) {
    commit(types.READ_NOTIFICATIONS);
  }
};
