export default {
  pharmacies: state => {
    return state.pharmacies;
  },
  pharmacy: state => {
    return state.pharmacy;
  },
  pharmacyPayInfo: state => {
    return state.pharmacyPayInfo
  },
  pharmacyGroup: state => {
    return state.pharmacyGroup
  }
};
