// Mutation Types
import * as types from "./types";

// State

export default {
  [types.GET_POSITIONS](state, payload) {
    state.positions = payload.results;
  },
  [types.GET_TITLES](state, payload) {
    state.titles = payload.results;
  },
  [types.GET_TERMS](state, payload) {
    state.terms = payload.results;
  },
  [types.GET_USER_TERMS](state, payload) {
    state.userTerms = payload.results;
  },
  [types.GET_WORKING_DAYS](state, payload) {
    state.workingDays = payload.results;
  },
  [types.GET_COMPUTER_PROGRAMS](state, payload) {
    state.computerPrograms = payload.results;
  },
/*   [types.GET_FILTER_COMPUTER_PROGRAMS](state, payload) {
    state.filterComputerPrograms = payload.results;
  }, */
  [types.GET_CONTRACT_TYPES](state, payload) {
    state.contractTypes = payload.results;
  },
  [types.GET_LANGUAGES](state, payload) {
    state.languages = payload.results;
  },
  [types.GET_OPEN_HOURS](state, payload) {
    state.openHours = payload.results;
  },
  [types.GET_EXPERTISES](state, payload) {
    state.expertises = payload.results;
  },
  [types.GET_EXPERIENCE_FIELDS](state, payload) {
    state.experienceFields = payload.results;
  },
  [types.GET_GENDERS](state, payload) {
    state.genders = payload.results;
  },
  [types.GET_STATES](state, payload) {
    state.states = payload.results;
  },
  [types.GET_CANDIDATE_STATUS_CHOICES](state, payload) {
    state.candidateStatusChoices = payload.results;
  },
  [types.GET_PHARMACY_STATUS_CHOICES](state, payload) {
    state.pharmacyStatusChoices = payload.results;
  },
  [types.READ_NOTIFICATIONS](state) {
    state.newNotifications = false;
  },
  [types.GET_NOTIFICATIONS](state, payload) {
    state.notifications = payload.results;
    state.newNotifications =
      payload.results.filter((elm) => {
        return elm.unread === true;
      }).length > 0
        ? true
        : false;
  },
  [types.GET_KEYVALUES](state, payload) {
    state.keyValues = payload.results.reduce(function (result, item) {
      result[item.key] = item.value;
      return result;
    }, {});
  },
  [types.GET_PHARMACY_EXPERIENCE_YEARS](state, payload) {
    state.pharmacyExperienceYears = payload;
  },
  [types.GET_PHARMACY_WORKER_NUMBER](state, payload) {
    state.pharmacyWorkerNumber = payload;
  },
  [types.GET_COMPENSATION_MODEL](state, payload) {
    state.compensationModel = payload;
  },
  [types.GET_WORKING_HOURS](state, payload) {
    state.workingHours = payload;
  },
  [types.GET_UNIVERSITIES](state, payload) {
    state.universities = payload;
  },
  [types.GET_HOMOLOGATED_CHOICES](state, payload) {
    state.homologatedChoices = payload;
  },
};
