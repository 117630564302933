export const GET_POSITIONS = "GET_POSITIONS";
export const GET_TITLES = "GET_TITLES";
export const GET_TERMS = "GET_TERMS";
export const GET_USER_TERMS = "GET_USER_TERMS";
export const GET_WORKING_DAYS = "GET_WORKING_DAYS";
export const GET_COMPUTER_PROGRAMS = "GET_COMPUTER_PROGRAMS";
/* export const GET_FILTER_COMPUTER_PROGRAMS = "GET_FILTER_COMPUTER_PROGRAM"
 */export const GET_CONTRACT_TYPES = "GET_CONTRACT_TYPES";
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_OPEN_HOURS = "GET_OPEN_HOURS";
export const GET_EXPERTISES = "GET_EXPERTISES";
export const GET_EXPERIENCE_FIELDS = "GET_EXPERIENCE_FIELDS";
export const GET_GENDERS = "GET_GENDERS";
export const GET_STATES = "GET_STATES";
export const GET_CANDIDATE_STATUS_CHOICES = "GET_CANDIDATE_STATUS_CHOICES";
export const GET_PHARMACY_STATUS_CHOICES = "GET_PHARMACY_STATUS_CHOICES";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const READ_NOTIFICATIONS = "READ_NOTIFICATIONS";
export const GET_KEYVALUES = "GET_KEYVALUES";
export const GET_PHARMACY_EXPERIENCE_YEARS = "GET_PHARMACY_EXPERIENCE_YEARS";
export const GET_PHARMACY_WORKER_NUMBER = "GET_PHARMACY_WORKER_NUMBER";
export const GET_COMPENSATION_MODEL = "GET_COMPENSATION_MODEL";
export const GET_WORKING_HOURS = "GET_WORKING_HOURS";
export const GET_UNIVERSITIES = "GET_UNIVERSITIES";
export const GET_HOMOLOGATED_CHOICES = "GET_HOMOLOGATED_CHOICES";