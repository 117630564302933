import config from "@/config";
import ApiService from "@/services";

export const CoreService = {
  getPositions() {
    return ApiService.get(`${config.CORE_ENDPOINT}positions`);
  },
  getTitles() {
    return ApiService.get(`${config.CORE_ENDPOINT}titles`);
  },
  getTerms() {
    return ApiService.get(`${config.CORE_ENDPOINT}terms`);
  },
  getUserTerms() {
    return ApiService.get(`${config.CORE_ENDPOINT}user-terms`);
  },
  getWorkingDays() {
    return ApiService.get(`${config.CORE_ENDPOINT}working-days`);
  },
  getComputerPrograms() {
    return ApiService.get(`${config.CORE_ENDPOINT}computer-programs`);
  },
/*   getFilterComputerPrograms(payload) {
    return ApiService.get(`${config.CORE_ENDPOINT}computer-programs/${payload.type_data}/${payload.id_data}`);
  }, */
  getContractTypes() {
    return ApiService.get(`${config.CORE_ENDPOINT}contract-types`);
  },
  getOpenHours() {
    return ApiService.get(`${config.CORE_ENDPOINT}open-hours`);
  },
  getLanguages() {
    return ApiService.get(`${config.CORE_ENDPOINT}languages`);
  },
  getExpertises() {
    return ApiService.get(`${config.CORE_ENDPOINT}expertises`);
  },
  getExperienceFields() {
    return ApiService.get(`${config.CORE_ENDPOINT}experience-fields`);
  },
  getGenders() {
    return ApiService.get(`${config.CORE_ENDPOINT}genders`);
  },
  getStates() {
    return ApiService.get(`${config.CORE_ENDPOINT}states`);
  },
  getCandidateStatusChoices() {
    return ApiService.get(`${config.CORE_ENDPOINT}candidate-status-choices`);
  },
  getPharmacyStatusChoices() {
    return ApiService.get(`${config.CORE_ENDPOINT}pharmacy-status-choices`);
  },
  getExpertStatusChoices() {
    return ApiService.get(`${config.CORE_ENDPOINT}expert-status-choices`);
  },
  postContactForm(form) {
    return ApiService.post(`${config.CORE_ENDPOINT}contact-support`, form);
  },
  getKeyValues() {
    return ApiService.get(`${config.CORE_ENDPOINT}key-values`);
  },
  getPharmacyExperienceYears() {
    return ApiService.get(
      `${config.CORE_ENDPOINT}pharmacy-experience-years-choices`
    );
  },
  getPharmacyWorkerNumber() {
    return ApiService.get(
      `${config.CORE_ENDPOINT}pharmacy-worker-number-choices`
    );
  },
  getCompensationModel() {
    return ApiService.get(`${config.CORE_ENDPOINT}compensation-model-choices`);
  },
  getUniversities() {
    return ApiService.get(`${config.CORE_ENDPOINT}universities`);
  },
  getHomologatedChoices() {
    return ApiService.get(`${config.CORE_ENDPOINT}homologated-choices`);
  },
  getWorkingHours() {
    return ApiService.get(`${config.CORE_ENDPOINT}working-hours-choices`);
  },
  getUserPreferencesLanguage() {
    return ApiService.get(`${config.CORE_ENDPOINT}user-preferences/language`);
  },
  updateUserPreferencesLanguage(lang) {
    return ApiService.post(`${config.CORE_ENDPOINT}user-preferences/language`, {
      language: lang
    });
  },
  getUser() {
    return ApiService.get(`${config.CORE_ENDPOINT}users/me`);
  }
};

export default CoreService;
