import config from "@/config";
import ApiService from "@/services";

export const LanguageService = {
    getLanguageLeverls() {
        return ApiService.get(`${config.CORE_ENDPOINT}language-levels`);
    },
    createLanguageOffer(payload) {
        return ApiService.put(`${config.CORE_ENDPOINT}offers/languages/`, payload);
    }
};

export default LanguageService;
