// Mutation Types
import * as types from "./types";

// State
import initialState from "./state";

export default {
	[types.LOGIN](state, payload) {
		state.info = payload.user;
		state.childId = payload.user.child_id;
		state.groupId = payload.user.group_id;
		state.accessToken = payload.access_token;
		state.refreshToken = payload.refresh_token;
		state.isLogged = true;
	},
	[types.UPDATE_CURRENT_USER](state, payload) {
		state.info = payload;
		state.groupId = payload.group_id;
	},
	[types.INIT_USER_TYPE](state, payload) {
		state.initUserType = payload;
	},
	[types.REGISTER](state, payload) {
		state.info = payload.user;
		state.childId = payload.user.child_id;
		state.groupId = payload.group_id;
		state.accessToken = payload.access_token;
		state.refreshToken = payload.refresh_token;
		state.isLogged = true;
	},
	[types.ME](state, payload) {
		state.info = null;
		state.info = payload;
		state.childId = payload.child_id;
		state.groupId = payload.group_id;
		state.isLogged = true;
	},
	[types.LOGOUT](state) {
		Object.assign(state, initialState);
	},
	[types.USER_PREFERENCES_LANGUAGE](state, payload) {
		state.language = payload;
	},
	[types.DONT_SHOW_ADD_TO_HOMESCREEN_AGAIN](state) {
		state.showAddToHomescreen = false;
	},
	[types.DONT_SHOW_OFFER_EXPLAIN_AGAIN](state) {
		state.ShowOfferExplain = false;
	},
	[types.DONT_SHOW_CANDIDATE_INTEREST_AGAIN](state) {
		state.ShowCandidateInterest = false;
	},
	[types.UPDATE_CHILD_ID](state, payload) {
		state.childId = payload;
	}
};
