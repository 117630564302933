<template>
  <v-bottom-sheet v-model="show" inset hide-overlay>
    <v-row justify="center" class="ml-0">
      <v-col cols="12" md="6" sm="8" class="pr-0">
        <v-sheet
          style="background-color:transparent"
          class="text-left"
          :class="isLogged ? 'pb-14' : 'pb-0'"
        >
          <div class="font px-4">
            {{ $t("field.add_to_homescreen") }}
            <a
              @click="dontShowAgain"
              href="faqs"
              class="secondary--text font-weight-bold"
              >{{ $t("faqs.faqs") }}</a
            >
          </div>
          <v-row justify="center" class="pa-0 ma-0">
            <v-btn
              class="my-3 mx-5 white--text"
              color="secondary"
              @click="dontShowAgain"
              outlined
              rounded
              >{{ $t("field.ok") }}
            </v-btn>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-bottom-sheet>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["showSheet"],
  name: "android-sheet",
  computed: {
    ...mapGetters({ isLogged: "user/isLogged" })
  },
  methods: {
    dontShowAgain() {
      this.show = false;
      this.dontShowAddToHomeScreenAgain();
    },
    ...mapActions({
      dontShowAddToHomeScreenAgain: "user/dontShowAddToHomeScreenAgain"
    })
  },
  data() {
    return {
      show: this.showSheet
    };
  }
};
</script>
<style scoped>
.font {
  font-size: 12px;
}
</style>
