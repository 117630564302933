export default {
  titles: (state) => {
    return state.titles;
  },
  positions: (state) => {
    return state.positions;
  },
  terms: (state) => {
    return state.terms;
  },
  userTerms: (state) => {
    return state.userTerms;
  },
  workingDays: (state) => {
    return state.workingDays;
  },
  computerPrograms: (state) => {
    return state.computerPrograms;
  },
  contractTypes: (state) => {
    return state.contractTypes;
  },
  openHours: (state) => {
    return state.openHours;
  },
  languages: (state) => {
    return state.languages;
  },
  expertises: (state) => {
    return state.expertises;
  },
  experienceFields: (state) => {
    return state.experienceFields;
  },
  genders: (state) => {
    return state.genders;
  },
  states: (state) => {
    return state.states;
  },
  candidateStatusChoices: (state) => {
    return state.candidateStatusChoices;
  },
  pharmacyStatusChoices: (state) => {
    return state.pharmacyStatusChoices;
  },
  notifications: (state) => {
    return state.notifications;
  },
  newNotifications: (state) => {
    return state.newNotifications;
  },
  keyValues: (state) => {
    return state.keyValues;
  },
  pharmacyExperienceYears: (state) => {
    return state.pharmacyExperienceYears;
  },
  pharmacyWorkerNumber: (state) => {
    return state.pharmacyWorkerNumber;
  },
  compensationModel: (state) => {
    return state.compensationModel;
  },
  workingHours: (state) => {
    return state.workingHours;
  },
  universities: (state) => {
    return state.universities;
  },
  homologatedChoices: (state) => {
    return state.homologatedChoices;
  },
};
