// Mutation Types
import * as types from "./types";

// Services
import ExpertService from "@/services/ExpertService.js";

export default {
    async getExpertPharmacies({ commit },) {
        const data = await ExpertService.getExpertPharmacies().then(
            response => response.data
        );
        commit(types.GET_EXPERT_PHARMACIES, data);
    },
    async closeOffer(_,offer_id) {
        const response=await ExpertService.closeOffer(offer_id)
        return response
    },
};