// vue filters page

import Vue from "vue";

Vue.filter("formatAddress", function(offer) {
  // var country = offer.pharmacy.address.locality.state.country.name;
  // var locality = offer.pharmacy.address.locality.name;
  // return locality.concat(", ", country);
  return offer.pharmacy.address.formatted;
});
Vue.filter("pharmacyAddress", function(pharmacy) {
  // var country = pharmacy.address.locality.state.country.name;
  // var locality = pharmacy.address.locality.name;
  // return locality.concat(", ", country);
  return pharmacy.address.formatted;
});
Vue.filter("trim", function(string) {
  return string.trim();
});
Vue.filter("formatTime", function(time) {
  var timeArray = time.split(",");
  var result = timeArray[0].replace(" ago", "");
  return result;
});

Vue.filter("titleList", function(titles) {
  var result = "";
  titles.map(t => {
    result = result.concat(t.title + "; ");
  });
  return result.substr(0, result.length - 2);
});
