// Mutation Types
import * as types from "./types";

// State

export default {
    [types.GET_LANGUAGE_LEVELS](state, payload) {
        state.languageLevels = payload.results;
    },
    [types.CREATE_LANGUAGE_OFFER](state, payload) {
        state.languageoffer = payload;
    },
    [types.SET_LANGUAGE](state, value) {
        state.language = value
    }
};