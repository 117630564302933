import Vue from "vue";
import VueI18n from "vue-i18n";

import { languages } from "@/assets/locales";

// Set default locale based on Browser Settings
const defaultLocale =
  JSON.parse(localStorage.getItem("vuex"))?.language?.language ||
  navigator.language.split("-")[0] ||
  navigator.userLanguage;
const messages = Object.assign(languages);

Vue.use(VueI18n);

export default new VueI18n({
  locale: defaultLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: messages
});
