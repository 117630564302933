// Mutation Types
import * as types from "./types";

// Services
import OffersService from "@/services/OffersService.js";
import MatchService from "@/services/MatchService.js";

export default {
	async getOffers({ commit }, payload) {
		let data = await OffersService.getOffers(payload).then((response) => response.data);
		commit(types.GET_PHARMACY_OFFERS, data);
	},
	// temp solution for displaying all alquimia offers
	//FIXME remove method
	async getAlquimiaOffers({ commit }, payload) {
		let data = await OffersService.getAlquimiaOffers(payload).then((response) => response.data);
		commit(types.GET_PHARMACY_OFFERS, data);
	},
	async getPharmacyGroupOffers({ commit }, payload) {
		let data = await OffersService.getPharmacyGroupOffers(payload).then((response) => response.data);
		commit(types.GET_PHARMACY_OFFERS, data);
	},
	async getExpertOffers({ commit }, payload) {
		const data = await OffersService.getExpertOffers(payload).then((response) => response.data);
		commit(types.GET_EXPERT_OFFERS, data);
	},
	async getExpertPharmacyOffers({ commit }, payload) {
		const data = await OffersService.getExpertPharmacyOffers(payload).then((response) => response.data);
		commit(types.GET_EXPERT_PHARMACY_OFFERS, data);
	},
	async getOfferById({ commit }, offer_id) {
		const data = await OffersService.getOfferById(offer_id).then((response) => response.data);
		commit(types.GET_OFFER_BY_ID, data);
	},
	async getOfferCandidates({ commit }, offer_id) {
		const data = await OffersService.getOfferCandidates(offer_id).then((response) => response.data);
		commit(types.GET_OFFER_CANDIDATES, data);
	},
	async changeCandidateOfferStatus(_, payload) {
		const response = await OffersService.changeCandidateOfferStatus(payload);

		return response;
	},
	async changePharmacyOfferStatus(_, payload) {
		const response = await OffersService.changePharmacyOfferStatus(payload);

		return response;
	},
	async changeExpertOfferStatus(_, payload) {
		const response = await OffersService.changeExpertOfferStatus(payload);

		return response;
	},
	async addPharmacyNotes(_, payload) {
		const response = await OffersService.addPharmacyNotes(payload);

		return response;
	},
	async createOffer({ commit }, payload) {
		const response = await OffersService.createOffer(payload);
		// if (payload.video)
		// await OffersService.uploadVideo(response.data.id,payload.video)

		commit(types.CREATE_OFFER, response.data);
		return response;
	},
	async updateOffer({ commit }, payload) {
		const data = await OffersService.updateOffer(payload).then((response) => response.data);
		commit(types.UPDATE_OFFER, data);
	},
	async getOfferLanguages({ commit }) {
		const data = await OffersService.getOfferLanguages().then((response) => response.data);
		commit(types.GET_OFFERS_LANGUAGES, data);
	},
	async createOfferLanguage({ commit }, payload) {
		const data = await OffersService.createOfferLanguage(payload).then((response) => response.data);
		commit(types.CREATE_OFFER_LANGUAGUE, data);
	},
	async updateOfferLanguage({ commit }, payload) {
		const data = await OffersService.updateOfferLanguage(payload).then((response) => response.data);
		commit(types.UPDATE_OFFER_LANGUAGUE, data);
	},
	async getMatchs({ commit }) {
		const data = await MatchService.getMatchs().then((response) => response.data);
		commit(types.GET_MATCHES, data);
	},
	async getMatchById({ commit }, match_id) {
		const data = await MatchService.getMatchById(match_id).then((response) => response.data);
		commit(types.GET_MATCH_BY_ID, data);
	},
	async createMatch({ commit }, payload) {
		const data = await MatchService.createMatch(payload).then((response) => response.data);
		commit(types.CREATE_MATCH, data);
	},
	async updateMatch({ commit }, payload) {
		const data = await MatchService.updateMatch(payload).then((response) => response.data);
		commit(types.UPDATE_MATCH, data);
	},
	async deleteDraft(_, offer_id) {
		const response = await OffersService.deleteDraft(offer_id);
		return response;
	}
};
