import config from "@/config";
import ApiService from "@/services";

export const OffersService = {
  getOffers(payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}offers/`, payload);
  },
  // temp solution for displaying all alquimia offers
  //FIXME remove method
  getAlquimiaOffers(payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}offers/alquimia/`, payload);
  },
  getPharmacyGroupOffers(payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}offers/group/`, payload);
  },
  getExpertOffers() {
    return ApiService.get(`${config.CORE_ENDPOINT}offers/expert-list`);
  },
  getExpertPharmacyOffers(payload) {
    return ApiService.query(`${config.CORE_ENDPOINT}offers/expert-list`, {
      pharmacy_id: payload.id
    });
  },
  getOfferById(offer_id) {
    return ApiService.get(`${config.CORE_ENDPOINT}offers/${offer_id}`);
  },
  getOfferCandidates(offer_id) {
    return ApiService.get(
      `${config.CORE_ENDPOINT}offers/${offer_id}/candidates`
    );
  },
  changeCandidateOfferStatus(payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}offers/${payload.offer_id}/candidate-status/`,
      {
        status: payload.status,
        candidate_id: payload.candidate_id
      }
    );
  },
  changePharmacyOfferStatus(payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}offers/${payload.offer_id}/pharmacy-status/`,
      {
        status: payload.status,
        candidate_id: payload.candidate_id
      }
    );
  },
  changeExpertOfferStatus(payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}offers/${payload.offer_id}/expert-status/`,
      {
        status: payload.status,
        candidate_id: payload.candidate_id
      }
    );
  },
  addPharmacyNotes(payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}offers/${payload.offer_id}/notes/`,
      {
        notes: payload.notes,
        candidate_id: payload.candidate_id
      }
    );
  },
  createOffer(payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}offers/`, {
      title: payload.title,
      // publish_language: payload.publish_language,
      typeOfService: payload.typeOfService,
      active: payload.active,
      expertise: payload.expertise,
      contract_type: payload.contract_type,
      working_day: payload.working_day,
      position: payload.position,
      must_be_near: payload.must_be_near,
      experience_required: payload.experience_required,
      salary: payload.salary,
      draft: payload.draft,
      offer_languages: payload.offer_languages,
      working_hours_comment: payload.working_hours_comment,
      remuneration_model: payload.remuneration_model,
      incentives: payload.incentives,
      working_hours: payload.working_hours,
      incorporation_date: payload.incorporation_date,
      pharmacy: payload.pharmacy,
      experience_fields: payload.experience_fields
      // gender_preference:payload.gender_preference
    });
  },
  updateOffer(payload) {
    return ApiService.put(`${config.CORE_ENDPOINT}offers/${payload.id}`, {
      title: payload.title,
      // publish_language: payload.publish_language,
      expertise: payload.expertise,
      contract_type: payload.contract_type,
      working_day: payload.working_day,
      position: payload.position,
      must_be_near: payload.must_be_near,
      experience_required: payload.experience_required,
      salary: payload.salary,
      offer_languages: payload.offer_languages,
      working_hours_comment: payload.working_hours_comment,
      remuneration_model: payload.remuneration_model,
      incentives: payload.incentives,
      working_hours: payload.working_hours,
      incorporation_date: payload.incorporation_date,
      experience_fields: payload.experience_fields,
      pharmacy: payload.pharmacy
    });
  },
  deleteOffer(offer_id) {
    return ApiService.delete(`${config.CORE_ENDPOINT}offers/`, offer_id);
  },

  getOfferLanguages() {
    return ApiService.get(`${config.CORE_ENDPOINT}offers/languages`);
  },
  getOfferLanguageById(language_id) {
    return ApiService.get(
      `${config.CORE_ENDPOINT}offers/languages/${language_id}`
    );
  },
  createOfferLanguage(payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}offers/languages/`, {
      offer: payload.offer,
      candidate: payload.candidate,
      status: payload.status,
      note: payload.note
    });
  },
  updateOfferLanguage(payload) {
    return ApiService.put(
      `${config.CORE_ENDPOINT}offers/languages/${payload.id}`,
      {
        offer: payload.offer,
        candidate: payload.candidate,
        status: payload.status,
        note: payload.note
      }
    );
  },
  deleteOfferLanguage(language_id) {
    return ApiService.delete(
      `${config.CORE_ENDPOINT}offers/languages/`,
      language_id
    );
  },
  uploadVideo(offer_id, video) {
    let formData = new FormData();
    formData.append("video", video, video.name);
    return ApiService.put(
      `${config.CORE_ENDPOINT}offers/${offer_id}/files/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
  },
  pay(payload) {
    return ApiService.post(
      `${config.CORE_ENDPOINT}pharmacy-stripe/create-checkout-session/`,
      {
        offer_id: payload.offer_id,
        product: payload.product
      }
    )
      .then(function(response) {
        return response.data;
      })
      .then(function(session) {
        return payload.stripe.redirectToCheckout({ sessionId: session.id });
      })
      .then(function(result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.
        if (result.error) {
          alert(result.error.message);
        }
      });
  },
  deleteDraft(offer_id) {
    return ApiService.delete(`${config.CORE_ENDPOINT}offers`, offer_id);
  }
};

export default OffersService;
