// Mutation Types
import * as types from "./types";

// State

export default {
  [types.GET_CANDIDATE_OFFERS](state, payload) {
    state.offers = payload.map(match => {
      let offer = match.offer
      offer.status = {
        pharmacy_status: match.pharmacy_status,
        candidate_status: match.candidate_status,
      }
      return offer
    });
  },
  [types.GET_EXPERT_OFFERS](state, payload) {
    state.offers = payload.results;
  },
   [types.GET_EXPERT_PHARMACY_OFFERS](state, payload) {
    state.offers = payload.results;
  },
  [types.GET_PHARMACY_OFFERS](state, payload) {
    state.offers = payload.results
  },
  [types.GET_OFFER_BY_ID](state, payload) {
    state.offer = payload
  },
  [types.GET_OFFER_CANDIDATES](state, payload) {
    state.candidates = payload.results;
  },
  [types.CREATE_OFFER](state, payload) {
    state.offers.push(payload);
  },
  [types.UPDATE_OFFER](state, payload) {
    state.offers[state.offers.indexOf(x => x.id == payload.id)] = payload;
  },
  [types.GET_OFFERS_LANGUAGES](state, payload) {
    state.offerLanguages = payload.results;
  },
  [types.CREATE_OFFER_LANGUAGUE](state, payload) {
    state.offerLanguages.push(payload);
  },
  [types.UPDATE_OFFER_LANGUAGUE](state, payload) {
    state.offerLanguages[state.offerLanguages.indexOf(x => x.id == payload.id)] = payload;

  },
  [types.GET_MATCHES](state, payload) {
    state.matches = payload.results;
  },
  [types.GET_MATCH_BY_ID](state, payload) {
    state.match = payload;
  },
  [types.CREATE_MATCH](state, payload) {
    state.matches.push(payload);
  },
  [types.UPDATE_MATCH](state, payload) {
    state.matches[state.matches.indexOf(x => x.id == payload.id)] = payload;
  },
};
