<template>
  <v-bottom-navigation background-color="secondary" horizontal grow fixed>
    <v-btn
      :to="{ name: 'offers' }"
      color="transparent"
      style="min-height: 55px"
    >
      <span class="white--text text-caption">{{ $tc("offer.title", 2) }}</span>
      <v-icon color="white" class="ma-0">mdi-briefcase</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
export default {};
</script>
