export default {
  offers: state => {
    return state.offers;
  },
  offer: state => {
    return state.offer;
  },
  offerLanguagues: state => {
    return state.offerLanguagues;
  },
  matches: state => {
    return state.matches;
  },
  match: state => {
    return state.match;
  },
  candidates: state => {
    return state.candidates;
  },

};
