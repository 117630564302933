// Mutation Types
import * as types from "./types";

export default {
  enableLoading({ commit }) {
    commit(types.SET_LOADING, true);
  },
  disableLoading({ commit }) {
    commit(types.SET_LOADING, false);
  },
};
