export default {
  positions: [],
  titles: [],
  terms: [],
  userTerms: [],
  workingDays: [],
  computerPrograms: [],
/*   filterComputerPrograms: [],
 */  othersComputerPrograms: [],
  openHours: [],
  expertises: [],
  experienceFields: [],
  genders: [],
  contractTypes: [],
  languages: [],
  states: [],
  pharmacyStatusChoices: [],
  candidateStatusChoices: [],
  notifications: [],
  newNotifications: false,
  keyValues: {},
  pharmacyExperienceYears: [],
  pharmacyWorkerNumber: [],
  compensationModel: [],
  workingHours: [],
  universities: [],
  homologatedChoices: []
};
