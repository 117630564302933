import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		options: { customProperties: true },
		themes: {
			light: {
				primary: "#13454C",
				secondary: "#0D7584",
				tertiary: "#718F94",
				danger: "#DB2828",
				success: "#3A9B02"
			}
		}
	}
});
