import config from "@/config";
import ApiService from "@/services";

export const AuthService = {
	login(payload) {
		return ApiService.post(`${config.AUTH_ENDPOINT}login/`, {
			email: payload.email,
			password: payload.password
		});
	},
	loginFacebook(payload) {
		return ApiService.post(`${config.AUTH_ENDPOINT}facebook/`, {
			access_token: payload.access_token,
			code: payload.code,
			id_token: payload.id_token
		});
	},
	loginGoogle(payload) {
		return ApiService.post(`${config.AUTH_ENDPOINT}google/`, {
			access_token: payload.access_token,
			code: payload.code,
			id_token: payload.id_token
		});
	},
	loginLinkedin(payload) {
		return ApiService.post(`${config.AUTH_ENDPOINT}linkedin/`, {
			access_token: payload.access_token,
			code: payload.code,
			id_token: payload.id_token
		});
	},
	logout() {
		return ApiService.post(`${config.AUTH_ENDPOINT}logout/`, {});
	},

	registration(payload) {
		return ApiService.post(`${config.AUTH_ENDPOINT}registration/`, {
			username: payload.email,
			email: payload.email,
			password1: payload.password,
			password2: payload.password,
			init_user_type: payload.type,
		});
	},
	registrationVerifyEmail(key) {
		return ApiService.post(`${config.AUTH_ENDPOINT}registration/verify-email/`, {
			key: key
		});
	},
	passwordChange(payload) {
		return ApiService.post(`${config.AUTH_ENDPOINT}password/change/`, {
			old_password: payload.old_password,
			new_password1: payload.new_password1,
			new_password2: payload.new_password2
		});
	},
	passwordReset(payload) {
		return ApiService.post(`${config.AUTH_ENDPOINT}password/reset/`, {
			email: payload.email
		});
	},
	passwordResetConfirm(payload) {
		return ApiService.post(`${config.AUTH_ENDPOINT}password/reset/confirm/`, {
			new_password1: payload.new_password1,
			new_password2: payload.new_password2,
			uid: payload.uid,
			token: payload.token
		});
	},
	tokenRefresh(payload) {
		return ApiService.post(`${config.AUTH_ENDPOINT}token/refresh/`, {
			refresh: payload.refresh
		});
	},
	tokenVerify(payload) {
		return ApiService.post(`${config.AUTH_ENDPOINT}token/verify/`, {
			token: payload.token
		});
	},
	getCurrentUser() {
		return ApiService.get(`${config.AUTH_ENDPOINT}user`);
	},

	putCurrentUser(payload) {
		return ApiService.put(`${config.AUTH_ENDPOINT}user/`, {
			username: payload.username,
			first_name: payload.first_name,
			last_name: payload.last_name,
			is_superuser: payload.is_superuser,
			is_active: payload.is_active,
			is_staff: payload.is_staff
		});
	},
	register(payload) {
		return ApiService.post(`${config.CORE_ENDPOINT}register/`, payload);
	},
	generateUser(payload) {
		return ApiService.post(`${config.CORE_ENDPOINT}users/generate-user/`, {
			email: payload.email,
			password: payload.password
		});
	},
	deleteUser(username) {
		return ApiService.delete(`${config.CORE_ENDPOINT}users`, username);
	}
};

export default AuthService;
