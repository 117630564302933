import config from "@/config";
import ApiService from "@/services";

export const LegalService = {
    getTermsAndConditions() {
        return ApiService.query(`${config.CORE_ENDPOINT}terms/`);
    },
    getFaqs(payload) {
        return ApiService.query(`faqs/questions`, {
            topic__name__iexact: payload.topic
        });
    },
    getprivacyPolicy() {
        return ApiService.query(`core/privacy-policy`);
    }
};

export default LegalService;
