export const GET_CANDIDATE_OFFERS = "GET_CANDIDATE_OFFERS";
export const GET_EXPERT_OFFERS = "GET_EXPERT_OFFERS";
export const GET_EXPERT_PHARMACY_OFFERS = "GET_EXPERT_PHARMACY_OFFERS";
export const GET_PHARMACY_OFFERS = "GET_PHARMACY_OFFERS";
export const GET_OFFER_BY_ID = "GET_OFFER_BY_ID";
export const GET_OFFER_CANDIDATES = "GET_OFFER_CANDIDATES";
export const CREATE_OFFER = "CREATE_OFFER";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const UPDATE_CANDIDATE_OFFER_STATUS = "UPDATE_CANDIDATE_OFFER_STATUS";
export const GET_OFFERS_LANGUAGES = "GET_OFFERS_LANGUAGES";
export const CREATE_OFFER_LANGUAGUE = "CREATE_OFFER_LANGUAGUE";
export const UPDATE_OFFER_LANGUAGUE = "UPDATE_OFFER_LANGUAGUE";
export const GET_MATCHES = "GET_MATCHES";
export const GET_MATCH_BY_ID = "GET_MATCH_BY_ID";
export const CREATE_MATCH = "CREATE_MATCH";
export const UPDATE_MATCH = "UPDATE_MATCH";