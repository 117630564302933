import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import config from "@/config";
import store from "@/store";
import Router from "@/router";
import i18n from "@/i18n";

export const ApiService = {
	init() {
		Vue.use(VueAxios, axios);
		Vue.axios.defaults.baseURL = config.API_URL;
		Vue.axios.interceptors.request.use(this.requestInterceptor, this.handleError);
		Vue.axios.interceptors.response.use(this.responseInterceptor, this.handleError);
	},

	requestInterceptor(config) {
		const accessToken = store.getters["user/accessToken"];
		config.headers["Accept-Language"] = i18n.locale;
		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
		return config;
	},

	responseInterceptor(response) {
		return response;
	},

	handleError(error) {
		// https://github.com/axios/axios/issues/934#issuecomment-322003342
		// If response error is 403 then Refresh Access Token & store in Vuex!
		if (error.config && error.response && error.response.status === 403) {
			store.dispatch("user/logout");
			Router.push({ name: "login" });
		}
		return Promise.reject(error);
	},

	query(resource, params) {
		resource = resource.endsWith("/") ? resource : `${resource}/`;
		return Vue.axios.get(`${resource}`, { params });
	},

	get(resource, slug = "", options) {
		slug = slug ? `${slug}/` : slug;
		return Vue.axios.get(`${resource}/${slug}`, options).catch((error) => {
			throw new Error(`APIService: ${error}`);
		});
	},

	post(resource, params, options) {
		resource = resource.endsWith("/") ? resource : `${resource}/`;
		return Vue.axios.post(`${resource}`, params, options);
	},

	update(resource, slug, params, options) {
		return Vue.axios.put(`${resource}/${slug}/`, params, options);
	},
	patch(resource, slug, params, options) {
		return Vue.axios.patch(`${resource}/${slug}/`, params, options);
	},

	put(resource, params, options) {
		resource = resource.endsWith("/") ? resource : `${resource}/`;
		return Vue.axios.put(`${resource}`, params, options);
	},

	delete(resource, slug, options) {
		return Vue.axios.delete(`${resource}/${slug}/`, options).catch((error) => {
			throw new Error(`APIService: ${error}`);
		});
	}
};

export default ApiService;
