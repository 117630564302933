export default {
  candidates: state => {
    return state.candidates;
  },
  candidate: state => {
    return state.candidate;
  },
  otherFiles: state => {
    return state.otherFiles;
  },
  expertFiles: state => {
    return state.expertFiles
  }
};
