// Mutation Types
import * as types from "./types";

// Services
import AuthService from "@/services/AuthService.js";
import PharmacyService from "@/services/PharmacyService.js";
import CandidateService from "@/services/CandidateService.js";
import CoreService from "../../services/CoreService";
import _ from "lodash";

export default {
  async login({ commit }, payload) {
    const response = await AuthService.login(payload);
    commit(types.LOGIN, response.data);
    return response.status;
  },
  async logout({ commit }) {
    commit(types.LOGOUT);
    await AuthService.logout();
  },
  async registration(_, payload) {
    let response = await AuthService.registration(payload);
    return response;
  },
  async updateInitUserType({ commit }, payload) {
    commit(types.INIT_USER_TYPE, payload);
  },
  async registerPharmacy({ dispatch }, payload) {
    //logo is sent separately
    let response = await PharmacyService.registerPharmacy(payload.pharmacy);
    if (payload.pharmacy.logo)
      PharmacyService.uploadLogo(response.data.id, payload.pharmacy.logo);

    payload.pharmacyOwner.pharmacy = response.data.id;
    payload.pharmacyContact.pharmacy = response.data.id;

    response = await PharmacyService.registerPharmacyOwner(
      payload.pharmacyOwner
    );
    if (payload.pharmacyContact.email)
      response = await PharmacyService.registerPharmacyContact(
        payload.pharmacyContact
      );
    if (payload.pharmacy.video)
      PharmacyService.uploadVideo(response.data.id, payload.pharmacy.video);
    // response=await AuthService.login(payload.user)
    // commit(types.LOGIN,response.data)
    response = dispatch("getUser");
    return response;
  },

  async registerCandidate({ dispatch }, payload) {
    let clonePayload = _.cloneDeep(payload);
    let othersComputerPrograms = await _.cloneDeep(
      payload.candidate.othersComputerPrograms
    );
    let simpleOthersComputerPrograms = [];
    for (let othersComputerProgram of othersComputerPrograms) {
      simpleOthersComputerPrograms.push(othersComputerProgram.programs);
    }
    clonePayload.candidate.othersComputerPrograms = await _.cloneDeep(
      simpleOthersComputerPrograms
    );
    let response = await CandidateService.registerCandidate(
      clonePayload.candidate
    );

    //upload files
    // ----------------------------------------------------------------------//
    CandidateService.uploadFiles(response.data.id, payload.candidate);

    // response = await AuthService.login(payload.user);
    // commit(types.LOGIN, response.data);
    response = dispatch("getUser");
    return response;
  },
  async getUserPreferencesLanguage({ commit }) {
    let response = await CoreService.getUserPreferencesLanguage();

    commit(types.USER_PREFERENCES_LANGUAGE, response.data.language);
    return response.lan;
  },
  async updateUserPreferencesLanguage({ commit }, lang) {
    CoreService.updateUserPreferencesLanguage(lang);

    commit(types.USER_PREFERENCES_LANGUAGE, lang);
  },
  async getUser({ commit }) {
    let response = await CoreService.getUser();
    await commit(types.ME, response.data); //must commit before anything else gets done
    return response;
  },
  async dontShowAddToHomeScreenAgain({ commit }) {
    commit(types.DONT_SHOW_ADD_TO_HOMESCREEN_AGAIN);
  },
  async dontShowOfferExplainAgain({ commit }) {
    commit(types.DONT_SHOW_OFFER_EXPLAIN_AGAIN);
  },
  async dontShowCandidateInterestAgain({ commit }) {
    commit(types.DONT_SHOW_CANDIDATE_INTEREST_AGAIN);
  },
  async updateChildId({ commit }, childId) {
    commit(types.UPDATE_CHILD_ID, childId);
  },
  async generateUser(_, payload) {
    let response = await AuthService.generateUser(payload);
    return [response.status, response.data];
  },
  async getCurrentUser({ commit }) {
    let response = await AuthService.getCurrentUser();
    commit(types.UPDATE_CURRENT_USER, response.data);
    return response.status;
  },
};
