// Mutation Types
import * as types from "./types";

// State

export default {
	[types.GET_PHARMACIES](state, payload) {
		state.pharmacies = payload.results;
	},
	[types.CREATE_PHARMACY](state, payload) {
		state.pharmacies.push(payload);
	},
	[types.GET_PHARMACY_BY_ID](state, payload) {
		state.pharmacy = payload;
	},
	[types.UPDATE_PHARMACY](state, payload) {
		state.pharmacies[state.pharmacies.indexOf((x) => x.id == payload.id)] = payload;
	},
	[types.UPDATE_PHARMACY_GROUP](state, payload) {
		state.pharmacyGroup = payload;
	},
	[types.GET_PHARMACY_PAY_INFO](state, payload) {
		state.pharmacyPayInfo = payload;
	},
	[types.GET_PHARMACY_GROUP_BY_ID](state, payload) {
		state.pharmacyGroup = payload;
	},
	[types.CREATE_PHARMACY_GROUP](state, payload) {
		state.pharmacyGroup = payload;
	}
};
