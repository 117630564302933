export default {
  termsAndConditions: state => {
    return state.termsAndConditions;
  },
  faqs: state => {
    return state.faqs;
  },
  privacyPolicy: state => {
    return state.privacyPolicy
  }
};
