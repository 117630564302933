// Mutation Types
import * as types from "./types";

// State

export default {
  [types.GET_MATCHES](state, payload) {
    state.matches=payload
  },
  [types.GET_CANDIDATES](state,payload) {
    state.candidates=payload.map(elm => {
      return {...elm.candidate,...{pharmacy_status: elm.pharmacy_status,candidate_status: elm.candidate_status,expert_status:elm.expert_status}}
    })
  },
  [types.GET_CANDIDATE](state,payload) {
    state.candidate={...payload.candidate,...{candidate_status: payload.candidate_status,pharmacy_status: payload.pharmacy_status,expert_status: payload.expert_status,notes:payload.notes}}
  },
  [types.GET_OFFERS](state,payload) {
    state.offers=payload.map(elm => {
      let offer=elm.offer
      delete elm.offer
      return {...offer,...elm}
    })
  },
  [types.GET_OFFER](state,payload) {
    let offer=payload.offer
    delete payload.offer
    state.offer={...offer,...payload}
  },
  
  
};
