import config from "@/config";
import ApiService from "@/services";

export const PharmacyService = {
  registerPharmacy(payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}pharmacies/`, {
      user_fk: payload.user,
      address: payload.address,
      cif: payload.cif,
      soe: payload.soe,
      web: payload.web,
      billing_address: payload.billing_address,
      open_hour: payload.open_hour,
      number: payload.number,
      commercial_name: payload.commercial_name,
      fiscal_name: payload.fiscal_name,
      phone_number: payload.phone_number,
      employees_number: payload.employees_number,
      billing_match_data: payload.billing_match_data,
      accept_terms_conditions: payload.accept_terms_conditions,
      accept_privacy_policy: payload.accept_privacy_policy,
      experience_required: payload.experience_required,
      group: payload.group,
      computer_programs: payload.computer_programs,
      other_computer_programs: payload.othersComputerPrograms,
    });
  },
  registerPharmacyOwner(payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}pharmacy-owners/`, {
      pharmacy: payload.pharmacy,
      full_name: payload.full_name,
      phone_number: payload.phone_number,
    });
  },

  registerPharmacyContact(payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}pharmacy-contacts/`, {
      pharmacy: payload.pharmacy,
      full_name: payload.full_name,
      phone_number: payload.phone_number,
      email: payload.email,
    });
  },
  createPharmacyGroup(payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}pharmacy-groups/`, {
      user: payload.user,
      name: payload.name,
      fiscal_name: payload.fiscal_name,
      representative: payload.representative,
      email: payload.email,
      phone_number: payload.phone_number,
      cif: payload.cif,
      billing_address: payload.billing_address,
    });
  },
  getPharmacies(group_id) {
    return ApiService.query(`${config.CORE_ENDPOINT}pharmacies/group`, {
      group_id: group_id,
    });
  },
  getPharmacy() {
    // for pharmacy user, getPharmacies would be for the administrator
    // just more explicit
    return ApiService.get(`${config.CORE_ENDPOINT}pharmacies`);
  },
  getPharmacyGroup(pharmacy_group_id) {
    return ApiService.get(
      `${config.CORE_ENDPOINT}pharmacy-groups/${pharmacy_group_id}`
    );
  },
  updatePharmacyGroup(payload) {
    return ApiService.patch(
      `${config.CORE_ENDPOINT}pharmacy-groups`,
      payload.id,
      payload
    );
  },
  getPharmacyById(pharmacy_id) {
    return ApiService.get(`${config.CORE_ENDPOINT}pharmacies/${pharmacy_id}`);
  },
  getPharmacyPayInfo(pharmacy_id) {
    return ApiService.query(`${config.CORE_ENDPOINT}pharmacy-pay-info`, {
      pharmacy_id: pharmacy_id,
    });
  },
  updatePharmacy(payload) {
    return ApiService.put(`${config.CORE_ENDPOINT}pharmacies/${payload.id}`, {
      address: payload.address,
      nif: payload.nif,
      billing_address: payload.billing_address,
      cif: payload.cif,
      soe: payload.soe,
      user_fk: payload.user.id,
      contacts: payload.contacts,
      pharmacy_owner: payload.pharmacy_owner,
      open_hour: payload.open_hour,
      number: payload.number,
      commercial_name: payload.commercial_name,
      fiscal_name: payload.fiscal_name,
      phone_number: payload.phone_number,
      web: payload.web,
      employees_number: payload.employees_number,
      billing_match_data: payload.billing_match_data,
      other_computer_programs: payload.others_computer_programs,
      computer_programs: payload.computer_programs,
      // accept_terms_conditions: payload.accept_terms_conditions,
      // accept_privacy_policy: payload.accept_privacy_policy,
      // logo: payload.logo
    });
  },
  deletePharmacy(pharmacy_id) {
    return ApiService.delete(`${config.CORE_ENDPOINT}pharmacies/`, pharmacy_id);
  },
  uploadLogo(pharmacy_id, logo) {
    let formData = new FormData();
    formData.append("logo", logo, logo.name);
    return ApiService.put(
      `${config.CORE_ENDPOINT}pharmacies/${pharmacy_id}/files/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  uploadVideo(pharmacy_id, video) {
    let formData = new FormData();
    formData.append("video", video, video.name);
    return ApiService.put(
      `${config.CORE_ENDPOINT}pharmacies/${pharmacy_id}/files/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  uploadGroupLogo(pharmacyGroup_id, logo) {
    let formData = new FormData();
    formData.append("logo", logo, logo.name);
    return ApiService.put(
      `${config.CORE_ENDPOINT}pharmacy-groups/${pharmacyGroup_id}/files/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },
  createGroup(payload) {
    return ApiService.post(`${config.CORE_ENDPOINT}pharmacy-groups/`, payload);
  },
};

export default PharmacyService;
