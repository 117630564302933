import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/offers/Offers.vue"),
      meta: {
        rule: "isUser",
        authRequired: true
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        rule: "isPublic",
        authRequired: false
      }
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/components/register/Register.vue"),
      meta: {
        rule: "isPublic",
        authRequired: false
      }
    },
    {
      path: "/register/pharmacy-group",
      name: "register-pharmacy-group",
      component: () => import("@/views/RegisterPharmacyGroup.vue"),
      meta: {
        rule: "isPublic",
        authRequired: false
      }
    },
    {
      path: "/register/validated",
      name: "register-validated",
      component: () => import("@/views/Register.vue"),
      meta: {
        rule: "isPublic",
        authRequired: false
      }
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("@/views/legal/Contact.vue"),
      meta: {
        rule: "isPublic",
        authRequired: false
      }
    },
    {
      path: "/faqs",
      name: "faqs",
      component: () => import("@/views/legal/Faqs.vue"),
      meta: {
        rule: "isPublic",
        authRequired: false
      }
    },
    {
      path: "/privacy-policy",
      name: "privacy-policy",
      component: () => import("@/views/legal/PrivacyPolicy.vue"),
      meta: {
        rule: "isPublic",
        authRequired: false
      }
    },
    {
      path: "/terms-and-conditions",
      name: "terms-and-conditions",
      component: () => import("@/views/legal/TermsAndConditions.vue"),
      meta: {
        rule: "isPublic",
        authRequired: false
      }
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/password/ForgotPassword.vue"),
      meta: {
        rule: "isPublic",
        authRequired: false
      }
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("@/views/password/ResetPassword.vue"),
      meta: {
        rule: "isPublic",
        uthRequired: false
      }
    },
    {
      path: "/email-send",
      name: "email-send",
      component: () => import("@/views/password/EmailSend.vue"),
      meta: {
        rule: "isPublic",
        authRequired: false
      }
    },
    {
      path: "/offers",
      name: "offers",
      component: () => import("@/views/offers/Offers.vue"),
      alias: "/",
      meta: {
        rule: "isUser",
        authRequired: true
      }
    },
    {
      path: "/offers/new",
      name: "offers-new",
      component: () => import("@/views/offers/NewOffer.vue"),
      meta: {
        rule: "isPharmacy",
        authRequired: true
      }
    },
    {
      path: "/offers/edit/:offer_id?/",
      name: "offers-edit",
      component: () => import("@/views/offers/EditOffer.vue"),
      meta: {
        rule: "isPharmacy",
        authRequired: true
      }
    },
    {
      path: "/pharmacy-data/:pharmacy_id?/",
      name: "pharmacy-data",
      component: () => import("@/views/offers/PharmacyData.vue"),
      meta: {
        rule: "isExpert",
        authRequired: true
      }
    },
    {
      path: "/pharmacy-detail/:offer_id?/",
      name: "pharmacy-detail",
      component: () => import("@/views/offers/PharmacyDetail.vue"),
      meta: {
        rule: "isPharmacyExpert",
        authRequired: true
      }
    },
    {
      path: "/candidate-profile",
      name: "candidate-profile",
      component: () => import("@/components/profiles/CandidateProfile.vue"),
      meta: {
        rule: "isCandidate",
        authRequired: true
      }
    },
    {
      path: "/pharmacy-profile",
      name: "pharmacy-profile",
      component: () => import("@/components/profiles/PharmacyProfile.vue"),
      meta: {
        rule: "isPharmacy",
        authRequired: true
      }
    },
    {
      path: "/candidate-detail/:offer_id?/",
      name: "candidate-detail",
      component: () => import("@/views/offers/CandidateDetail.vue"),
      meta: {
        rule: "isCandidate",
        authRequired: true
      }
    },
    {
      path: "/pharmacy-candidate-detail/:candidate_id?",
      name: "pharmacy-candidate-detail",
      component: () => import("@/views/offers/PharmacyCandidateDetail.vue"),
      meta: {
        rule: "isPharmacyExpert",
        authRequired: true
      }
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () => import("@/views/profile/Notifications.vue"),
      meta: {
        rule: "isUser",
        authRequired: true
      }
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/profile/Profile.vue"),
      meta: {
        rule: "isNormalUser",
        authRequired: true
      }
    },
    {
      path: "/transactions",
      name: "transactions",
      component: () => import("@/views/profile/Transactions.vue"),
      meta: {
        rule: "isPharmacy",
        authRequired: true
      }
    },
    {
      path: "/configuration",
      name: "configuration",
      component: () => import("@/views/profile/Configuration.vue"),
      meta: {
        rule: "isUser",
        authRequired: true
      }
    },
    {
      path: "/edit-profile",
      name: "edit-profile",
      component: () => import("@/views/profile/EditProfile.vue"),
      meta: {
        rule: "isNormalUser",
        authRequired: true
      }
    },
    {
      path: "/new-pharmacy",
      name: "new-pharmacy",
      component: () => import("@/components/profiles/NewPharmacy.vue"),
      meta: {
        rule: "isNormalUser",
        authRequired: true
      }
    },
    {
      path: "/edit-group-profile",
      name: "edit-group-profile",
      component: () => import("@/views/profile/EditGroupProfile.vue"),
      meta: {
        rule: "isNormalUser",
        authRequired: true
      }
    },
    {
      path: "/not_authorized",
      name: "not_authorized",
      component: () => import("@/views/pages/NotAuthorized.vue"),
      meta: {
        rule: "isPublic",
        authRequired: false
      }
    },
    // =============================================================================
    // ERROR PAGES
    // =============================================================================
    {
      path: "/404",
      name: "error404",
      component: () => import("@/views/pages/Error404.vue"),
      meta: {
        rule: "isPublic"
      }
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/404"
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.getters["user/isLogged"]) {
      return next({
        name: "login"
        // query: { next: to.fullPath }
      });
    }
  }
  return next();
});
export default router;
