export default {
	// API Configuration
	// -------------------------------------------------------------------
	//API_URL: process.env.VUE_APP_ROOT_API,
	API_URL: process.env.VUE_APP_ROOT_API,

	// Auth
	// ------------------------------------
	AUTH_ENDPOINT: "auth/",

	// Token
	// ------------------------------------
	TOKEN_ENDPOINT: "token/",

	// Core
	// ------------------------------------
	CORE_ENDPOINT: "core/",

	// Faqs
	// ------------------------------------
	FAQS_ENDPOINT: "faqs/",
	//Notifications
	NOTIFICATIONS_ENDPOINT: "inbox/notifications/api/",
	// Constants
	// ------------------------------------
	CONSTANTS: {
		CANDIDATE_USER_TYPE: "CANDIDATE",
		PHARMACY_USER_TYPE: "PHARMACY",
		PHARMACY_GROUP_USER_TYPE: "PHARMACYGROUP",
		GLOBAL_TOPIC: "GLOBAL",
		SERVICES: {
			ALQUIMIA: "alquimia",
			MAGISTER: "magister"
		},
		PHARMACIST_TITLE: 2, // id of title pharmacist
		OTHER_UNIVERSITY: 23 // Id of other university option
	}
};
