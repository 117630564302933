<template>
  <v-app>
    <v-main
      class="primary--text"
      v-bind:class="{ backgroundImage: addBackground }"
    >
      <div class="onesignal-customlink-container"></div>
      <router-view></router-view>
    </v-main>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate :size="64"></v-progress-circular>
    </v-overlay>
    <notifications position="bottom right" :width="notificationWidth" />
    <direct-access
      :showSheet="showAddToHomescreen && standalone"
    ></direct-access>
    <v-footer padless v-if="isLogged && childId != null">
      <Footer />
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as Sentry from "@sentry/vue";
import Footer from "@/components/layouts/Footer.vue";
import { Network } from '@capacitor/network';

Network.addListener('networkStatusChange', status => {
  console.log('Network status changed', status);
});

 async () => {
  const status = await Network.getStatus();

  console.log('Network status:', status);
};
// import iosSheet from "@/components/bottom-sheets/ios.vue";
// import androidSheet from "@/components/bottom-sheets/android.vue";
import DirectAccess from "@/components/bottom-sheets/DirectAccess.vue";
export default {
  name: "App",
  components: {
    Footer,
    // iosSheet,
    // androidSheet,
    DirectAccess,
  },
  data: () => ({
    showiOS: false,
    showAndroid: false,
    standalone: false,
  }),
  created() {
    // Detects if device is on iOS
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIos = () => {
      return /iphone|ipad|ipod/.test(userAgent);
    };
    const isAndroid = () => {
      return /android/.test(userAgent);
    };
    const isChrome = () => {
      /chrome/.test(userAgent) && !/chromium/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      this.showiOS = true;
      this.standalone = isInStandaloneMode;
    } else if (isAndroid() && !isChrome() && !isInStandaloneMode()) {
      this.showAndroid = true;
      this.standalone = isInStandaloneMode;
    }
  },
  computed: {
    ...mapGetters({ loading: "application/loading" }),
    ...mapGetters({ language: "language/language" }),
    ...mapGetters({ childId: "user/childId" }),
    ...mapGetters({ isLogged: "user/isLogged" }),
    ...mapGetters({ info: "user/info" }),
    ...mapGetters({ userLanguage: "user/language" }),
    ...mapGetters({ showAddToHomescreen: "user/showAddToHomescreen" }),
    notificationWidth() {
      return this.$vuetify.breakpoint.smAndDown ? "60%" : "20%";
    },
    addBackground: function () {
      if (
        (this.$route.path === "/login" > 0)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions({ getNotifications: "core/getNotifications" }),
    ...mapActions({ getKeyValues: "core/getKeyValues" }),
    ...mapActions({
      getUserPreferencesLanguage: "user/getUserPreferencesLanguage",
    }),
    ...mapActions({ setLanguage: "language/setLanguage" }),
  },
  watch: {
    isLogged: async function () {
      if (this.isLogged == false) {
        clearInterval(this.interval);
      } else {
        if (process.env.VUE_APP_SENTRY_ENVIRONMENT == "production") {
          Sentry.setUser({
            email: this.info.email,
            username: this.info.username,
            id: this.info.id,
          });
          // eslint-disable-next-line no-undef
          FS.identify(this.info.id, {
            displayName: this.info.username,
            email: this.info.email,
            // TODO: Add your own custom user variables here, details at
            // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
          });
        }
        if (!this.language) {
          await this.getUserPreferencesLanguage();
          this.$i18n.locale = this.userLanguage;
          this.setLanguage(this.userLanguage);
        }
      }
    },
  },
  async mounted() {
    const minutes = 0.5;
    this.getKeyValues();
    if (this.isLogged) {
      // if (this.info.user_type === "default")
      //   this.$router.push({ name: "register-validated" });
      this.interval = setInterval(this.getNotifications, minutes * 60000);
    }
    this.$i18n.locale =
      this.language == null ? this.$i18n.locale : this.language;
    this.setLanguage(this.$i18n.locale);
  },
};
</script>
<style>
.pac-target-input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: transparent !important;
}
.pac-target-input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: transparent !important;
}
.pac-target-input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: transparent !important;
}
.pac-target-input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: transparent !important;
}
</style>
<style scoped>
.backgroundImage {
  background-image: url("assets/apotalent/background.svg");
  background-repeat: no-repeat;
  background-position: top center;
}
.container {
  min-height: 100%;
}
</style>
