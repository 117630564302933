export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const ME = "ME";
export const LOGOUT = "LOGOUT";
export const USER_PREFERENCES_LANGUAGE = "USER_PREFERENCES_LANGUAGE";
export const DONT_SHOW_ADD_TO_HOMESCREEN_AGAIN =
  "DONT_SHOW_ADD_TO_HOMESCREEN_AGAIN";
export const UPDATE_CHILD_ID = "UPDATE_CHILD_ID";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const DONT_SHOW_OFFER_EXPLAIN_AGAIN = "DONT_SHOW_OFFER_EXPLAIN_AGAIN";
export const DONT_SHOW_CANDIDATE_INTEREST_AGAIN =
  "DONT_SHOW_CANDIDATE_INTEREST_AGAIN";

export const INIT_USER_TYPE = "INIT_USER_TYPE"
