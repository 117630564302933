// Mutation Types
import * as types from "./types";

// Services
import MatchService from "@/services/MatchService.js";

export default {
  async getMatches({commit},payload) {
    let data=await MatchService.getMatches(payload).then(
      response => response.data.results
    );
    commit(types.GET_MATCHES,data);
  },
  async getCandidates({commit},payload) {
    let data=await MatchService.getCandidates(payload).then(
      response => response.data.results
    );
    commit(types.GET_CANDIDATES,data);
  },
  async getCandidate({commit},payload) {
    let data=await MatchService.getCandidate(payload).then(
      response => response.data.results[0]
    );
    commit(types.GET_CANDIDATE,data);
  },
  async getOffers({commit},payload) {
    let data=await MatchService.getOffers(payload).then(
      response => response.data
    );
    commit(types.GET_OFFERS,data);
  },
  async getOfferById({commit},offer_id) {
    let data=await MatchService.getOfferById(offer_id).then(
      response => response.data
    );
    commit(types.GET_OFFER,data);
  },
  
};
