import config from "@/config";
import ApiService from "@/services";

export const Expertservice = {
    getExpertPharmacies() {
        return ApiService.get(`${config.CORE_ENDPOINT}pharmacies/expert-list`);
    },
    closeOffer(offer_id) {
        return ApiService.put(`${config.CORE_ENDPOINT}offers/${offer_id}/expert-done`);
    },
};

export default Expertservice;
