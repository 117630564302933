import config from "@/config";
import ApiService from "@/services";

export const NotificationsService={
    getNotifications() {
        return ApiService.get(`${config.CORE_ENDPOINT}notifications`);
    },
    markAllNotificationsAsRead() {
        return ApiService.put(`${config.CORE_ENDPOINT}notifications/mark-as-read`);
    },
    
};

export default NotificationsService;
