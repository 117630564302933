import config from "@/config";
import ApiService from "@/services";

export const MatchService = {
    getMatches(payload) {
        return ApiService.query(`${config.CORE_ENDPOINT}matching`, {
            offer: payload.offer_id,
            candidate: payload.candidate_id,
            pharmacy_status: payload.pharmacy_status,
            candidate_status: payload.candidate_status
        });
    },
    getCandidates(payload) {
        return ApiService.query(`${config.CORE_ENDPOINT}matching`, {
            offer: payload.offer_id,
            pharmacy_status__isnull: payload.pharmacy_status,
            expert_status__isnull: payload.expert_status,
        });
    },
    getCandidate(payload) {
        return ApiService.query(`${config.CORE_ENDPOINT}matching`, {
            offer: payload.offer_id,
            candidate: payload.candidate_id,
            pharmacy_status__isnull: payload.userType=="pharmacy"?false:null,
            expert_status__isnull: payload.userType=="expert"? false:null,
            
        });
    },
    getOffers(payload) {
        return ApiService.query(`${config.CORE_ENDPOINT}matching/offers`, {
            offer: payload.offer_id,
            candidate: payload.candidate_id,
            candidate_status: payload.candidate_status
        });
    },
    getOfferById(payload) {
        return ApiService.query(`${config.CORE_ENDPOINT}matching/offers/${payload.offer_id}`, {
            candidate: payload.candidate_id,
        });
    },
    getMatchById(match_id) {
        return ApiService.get(`${config.CORE_ENDPOINT}matching/${match_id}`);
    },
};

export default MatchService;
