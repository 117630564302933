import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import user from "@/store/user";
import application from "@/store/application";
import offer from "@/store/offer";
import core from "@/store/core";
import candidate from "@/store/candidate";
import pharmacy from "@/store/pharmacy";
import language from "@/store/language";
import legal from "@/store/legal";
import match from "@/store/match";
import expert from "@/store/expert";

Vue.use(Vuex);

// Vuex Persistence
const vuexPersist = new VuexPersistence({
  storage: localStorage,
  modules: ["user", "language"],
  strictMode: process.env.NODE_ENV !== "production"
});

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  modules: {
    user: user,
    application: application,
    offer: offer,
    core: core,
    language: language,
    candidate: candidate,
    pharmacy: pharmacy,
    legal: legal,
    match: match,
    expert: expert
  },
  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION // this mutation **MUST** be named "RESTORE_MUTATION"
  },
  strict: process.env.NODE_ENV !== "production"
});
